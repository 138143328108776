import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]

import axiosClient from '@/api/axios';
import MyModal from '@/components/modal/MyModal.vue';
import { charge } from '@/global/states/ChargeState';
import router from '@/router';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { vMaska } from "maska/vue"
import { toast } from 'vue3-toastify'


export default /*@__PURE__*/_defineComponent({
  __name: 'GetOrderComponent',
  setup(__props) {

const route = useRoute()
const document = ref('')
const isValidateDocumentModalOpen = ref(false)
const isLoading = ref(false);

async function validateOrderWithDocument(): Promise<string> {
    const response = await axiosClient.get(`/orders/consult/${route.query.orderUUID}/${document.value.replace(/[.,-]/g, "")}`)

    return response.data.data.token
}

async function getOrderDetails(token: string) {
    const response = await axiosClient.get(`/orders/consult/${route.query.orderUUID}`, { headers: {
        Authorization: 'Bearer ' + token
    }})

    charge.value = response.data
}

async function handleValidateGetOrder() {
    if (isLoading.value) return

    isLoading.value = true;

    let token = localStorage.getItem('orderToken')

    if (!token) {
        token = await validateOrderWithDocument()

        if (!token) {
            toast.error("Documento inválido")
            document.value = ''
        }

        localStorage.setItem('orderToken', token)
    }

    try {
        await getOrderDetails(token)
    } catch {
        token = await validateOrderWithDocument()
        await getOrderDetails(token)
    } finally {
        isLoading.value = false;
        isValidateDocumentModalOpen.value = false
    }

}

onMounted(() => {
    if (!charge.value) {
        if (route.query.orderUUID) {
            isValidateDocumentModalOpen.value = true
        } else {
            router.push({ name: 'error' })
        }
    } 
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyModal, {
    "class-name": 'validate-document-modal',
    width: '365px',
    height: '240px',
    "is-open": isValidateDocumentModalOpen.value,
    "toggle-modal": (v) => {}
  }, {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "pb-10 mb-4" }, "Validação de documento", -1)),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        type: "tel",
        placeholder: "Digite seu documento",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((document).value = $event))
      }, null, 512), [
        [_vModelText, document.value],
        [_unref(vMaska), '###.###.###-##']
      ]),
      _createElementVNode("button", {
        disabled: document.value.length < 14,
        class: "bg-primary",
        onClick: handleValidateGetOrder
      }, [
        (isLoading.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[1] || (_cache[1] = _createElementVNode("span", {
                class: "spinner-border spinner-border-sm",
                role: "status",
                "aria-hidden": "true"
              }, null, -1)),
              _cache[2] || (_cache[2] = _createTextVNode(" Validando... "))
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(" Validar ")
            ], 64))
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})