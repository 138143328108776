import { ref } from "vue";

interface Payment {
  hasErrors: boolean;
  charges: Charge[];
}

interface Charge {
  uuid: string;
  amount: number;
  type: string;
  hasErrors: boolean;
  pixPayload?: string;
  pixQrCode?: string;
  billetUrl?: string;
  billetBarCode?: string;
  expireAt: string;
}

interface Phone {
  phoneNumber: string;
  masked: string;
  numbersOnly: string;
}

interface Document {
  number: string;
  type: string;
}

interface Customer {
  email: string;
  name: string;
  phone: Phone;
  document: Document;
}

interface Data {
  browser: string;
  version: string;
  platform: string;
  source: string;
  urlRefer: string;
  ip: string;
  cookie: string;
  callbackURL: string;
}

interface Commission {
  userId: number;
  type: string;
  amount: number;
}

interface Product {
  title: string;
  price: number;
  quantity: number;
  amount: number;
  discount: number;
}

interface Charge {
  payment: Payment;
  customer: Customer;
  data: Data;
  commissions: Commission[];
  products: Product[];
  createdAt: string;
  orderUUID: string;
  expiry: number
}

function getChargeWithExpiry(key: string): Charge | undefined {
  const chargeStr = localStorage.getItem(key);

  if (!chargeStr) {
      return undefined; // Retorna indefinido se não houver dados no localStorage
  }

  const charge = JSON.parse(chargeStr) as Charge;

  if (new Date().getTime() > new Date(charge.expiry).getTime() + (72 * 60 * 60 * 1000)) {
      localStorage.removeItem(key); // Remove o item expirado
      return undefined;
  }

  return charge;
}

export const charge = ref<Charge | undefined>(getChargeWithExpiry('charge'))
export const thankYouPages = ref<{ pageCreditCard: string, pagePix: string, pageBillet: string }>()