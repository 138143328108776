import axios from "axios";

const username = '43a65cec9e1b116cd677def37680b94b';
const password = 'ebacd57f5ec9bfce4ddde5e5cae80ac9ee22ed07c5a1273bc19bc2b2b77988d5';
const token = btoa(`${username}:${password}`);

const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        Authorization: `Basic ${token}`
    }
})

export default axiosClient