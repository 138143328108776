<template>
    <div class="thank-you">
        <section class="thank-you__payment-made pb-4">
            <svg class="my-4" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h1 class="text-">Pagamento efetuado!</h1>
        </section>
        <section class="thank-you__info">
            <div class="thank-you__info__container gap-4">
                <div class="d-flex flex-column gap-2">
                    <div class="d-flex align-items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 8V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.9946 16H12.0036" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="m-0">Informações</p>
                    </div>
                    <div class="thank-you__info__container__box border rounded-4">
                        <h2>Número do pedido: {{ charge.orderUUID }}</h2>
                        <h2>Produto: {{ charge.products.map((p) => p.title).join(', ') }}</h2>
                        <h2>Data de compra: {{ new Date(charge.createdAt).toLocaleDateString() }}</h2>
                    </div>
                </div>
                <div class="d-flex flex-column gap-2">
                    <div class="d-flex align-items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="m-0">Cliente</p>
                    </div>
                    <div class="thank-you__info__container__box border rounded-4">
                        <h2>Nome: {{ charge.customer.name }}</h2>
                        <h2>Documento: {{ formatDocument(String(charge.customer.document.number)) }}</h2>
                        <h2>Email: {{ charge.customer.email }}</h2>
                    </div>
                </div>
                <div v-if="address" class="d-flex flex-column gap-2">
                    <div class="d-flex align-items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.0098 2V12C15.0098 13.1 14.1098 14 13.0098 14H2.00977V6C2.00977 3.79 3.79977 2 6.00977 2H15.0098Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22.0098 14V17C22.0098 18.66 20.6698 20 19.0098 20H18.0098C18.0098 18.9 17.1098 18 16.0098 18C14.9098 18 14.0098 18.9 14.0098 20H10.0098C10.0098 18.9 9.10977 18 8.00977 18C6.90977 18 6.00977 18.9 6.00977 20H5.00977C3.34977 20 2.00977 18.66 2.00977 17V14H13.0098C14.1098 14 15.0098 13.1 15.0098 12V5H16.8498C17.5698 5 18.2298 5.39001 18.5898 6.01001L20.2998 9H19.0098C18.4598 9 18.0098 9.45 18.0098 10V13C18.0098 13.55 18.4598 14 19.0098 14H22.0098Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.00977 22C9.11434 22 10.0098 21.1046 10.0098 20C10.0098 18.8954 9.11434 18 8.00977 18C6.9052 18 6.00977 18.8954 6.00977 20C6.00977 21.1046 6.9052 22 8.00977 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.0098 22C17.1143 22 18.0098 21.1046 18.0098 20C18.0098 18.8954 17.1143 18 16.0098 18C14.9052 18 14.0098 18.8954 14.0098 20C14.0098 21.1046 14.9052 22 16.0098 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22.0098 12V14H19.0098C18.4598 14 18.0098 13.55 18.0098 13V10C18.0098 9.45 18.4598 9 19.0098 9H20.2998L22.0098 12Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="m-0">Informações de entrega</p>
                    </div>
                    <div class="thank-you__info__container__box border rounded-4">
                        <h2>Rua: {{ address.street }}</h2>
                        <h2>Número: {{ address.number }}</h2>
                        <h2>Complemento: {{ address.complement }}</h2>
                        <h2>Bairro: {{ address.neighborhood }}</h2>
                        <h2>Cidade: {{ address.city }}</h2>
                        <h2>Estado: {{ address.state }}</h2>
                    </div>
                </div>
                <div class="d-flex flex-column gap-2">
                    <div class="d-flex align-items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M17.0156 18.43H13.0156L8.56561 21.39C7.90561 21.83 7.01562 21.36 7.01562 20.56V18.43C4.01562 18.43 2.01562 16.43 2.01562 13.43V7.42993C2.01562 4.42993 4.01562 2.42993 7.01562 2.42993H17.0156C20.0156 2.42993 22.0156 4.42993 22.0156 7.42993V13.43C22.0156 16.43 20.0156 18.43 17.0156 18.43Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.0156 11.36V11.15C12.0156 10.47 12.4357 10.11 12.8557 9.82001C13.2657 9.54001 13.6756 9.18002 13.6756 8.52002C13.6756 7.60002 12.9356 6.85999 12.0156 6.85999C11.0956 6.85999 10.3557 7.60002 10.3557 8.52002" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.0111 13.75H12.0201" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="m-0">Suporte</p>
                    </div>
                    <div class="thank-you__info__container__box border rounded-4">
                        <h2>Email: {{ checkoutDetails.support.email }}</h2>
                        <h2>Telefone: {{ formatPhoneNumber(checkoutDetails.support.phone) }}</h2>
                        <h2>Website: {{ checkoutDetails.support.website }}</h2>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts" setup>
import { charge } from '@/global/states/ChargeState'
import useFormat from '@/utils/format/useFormat';

const { formatDocument, formatPhoneNumber } = useFormat()
const checkoutDetails = localStorage.getItem('checkoutDetails') ? JSON.parse(localStorage.getItem('checkoutDetails')!) : undefined
const address: Partial<{neighborhood: string, district: string, number: number, street: string, complement: string, state: string, city: string, ibge: string}> = localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')!) : undefined
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

#app {
    height: 100vh
}

.thank-you {
    background: #1eaf51;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: Inter, 'sans-serif';
    gap: 1rem;
    height: 100%;
    h2 {
        font-size: 1rem;
        font-weight: 400;
    }
    &__payment-made {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            color: #fff
        }
    }
    &__customer {
        background: #3a3b3b;
    }
    &__info {
        background: #f9fbfa;
        width: 100%; 
        padding: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: 100%;
        padding-top: 2rem;
        h2 {
            color: #3a3b3b
        }
        &__container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            &__box {
                background: #fff;
                border-color: #ddd !important;
                width: 500px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
                padding: 1.5rem;
                height: 100%;
                &:first-child {
                    height: 100%
                }
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .thank-you {
        &__info {
            &__container {
                &__box {
                    width: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .thank-you {
        &__info {
            &__container {
                grid-template-columns: 1fr;
            }
        }
    }
}
</style>