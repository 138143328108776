export default function useFormat() {
  function formatDocument(value?: string) {
    if (!value) {
      return 'Sem documento'
    }
  
    const cnpjCpf = value.replace(/\D/g, '');
    
    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    } 
    
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
  }

  function formatPhoneNumber(v: string) {
    const cleaned = ('' + v).replace(/\D/g, '');
  
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{4}|\d{5})(\d{4})$/);
    
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    
    return '';
  }  

  return {
    formatDocument,
    formatPhoneNumber
  }
}