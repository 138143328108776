<template>
<MyModal :class-name="'validate-document-modal'" :width="'365px'" :height="'240px'" :is-open="isValidateDocumentModalOpen" :toggle-modal="(v: boolean) => {}">
    <h5 class="pb-10 mb-4">Validação de documento</h5>
    <input class="form-control" type="tel" placeholder="Digite seu documento" v-model="document" v-maska="'###.###.###-##'">
    <button :disabled="document.length < 14" class="bg-primary" @click="handleValidateGetOrder">
        <template v-if="isLoading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Validando...
        </template>
        <template v-else>
            Validar
        </template>
    </button>
</MyModal>
</template>

<script lang="ts" setup>
import axiosClient from '@/api/axios';
import MyModal from '@/components/modal/MyModal.vue';
import { charge } from '@/global/states/ChargeState';
import router from '@/router';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { vMaska } from "maska/vue"
import { toast } from 'vue3-toastify'

const route = useRoute()
const document = ref('')
const isValidateDocumentModalOpen = ref(false)
const isLoading = ref(false);

async function validateOrderWithDocument(): Promise<string> {
    const response = await axiosClient.get(`/orders/consult/${route.query.orderUUID}/${document.value.replace(/[.,-]/g, "")}`)

    return response.data.data.token
}

async function getOrderDetails(token: string) {
    const response = await axiosClient.get(`/orders/consult/${route.query.orderUUID}`, { headers: {
        Authorization: 'Bearer ' + token
    }})

    charge.value = response.data
}

async function handleValidateGetOrder() {
    if (isLoading.value) return

    isLoading.value = true;

    let token = localStorage.getItem('orderToken')

    if (!token) {
        token = await validateOrderWithDocument()

        if (!token) {
            toast.error("Documento inválido")
            document.value = ''
        }

        localStorage.setItem('orderToken', token)
    }

    try {
        await getOrderDetails(token)
    } catch {
        token = await validateOrderWithDocument()
        await getOrderDetails(token)
    } finally {
        isLoading.value = false;
        isValidateDocumentModalOpen.value = false
    }

}

onMounted(() => {
    if (!charge.value) {
        if (route.query.orderUUID) {
            isValidateDocumentModalOpen.value = true
        } else {
            router.push({ name: 'error' })
        }
    } 
})
</script>

<style lang="scss">
.validate-document-modal {
    font-family: "Inter", sans-serif;
    .modal__content {
        padding: 1.5rem;
        input {
            width: 100% !important;
            outline: none !important;
            padding: .8rem 0.9rem 0.8rem 0.9rem !important;
            font-size: 0.9rem !important;
            font-weight: 400 !important;
            line-height: 1.5 !important;
            color: #212529 !important;
            background-color: #fff !important;
            background-clip: padding-box !important;
            border: 1px solid #ced4da;
            border-radius: 0.375rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
        }
    }
    button {
        margin-top: 48px;
        border-radius: 14px;
        color: #fff;
        padding: 10px;
        border: unset;
        outline: unset;
        font-weight: 500;
        &:disabled {
            background-color: #f5f5f5 !important;
            color: #6F6F6F;
            cursor: not-allowed;
        }
    }
}

.spinner-border {
    width: 1rem;
    height: 1rem;
    border: 0.2em solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
    animation: spinner 0.75s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
</style>