import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-3" }


import SpinnerLoader from './SpinnerLoader.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BackdropLoader',
  props: { zIndex: { type: Number, required: true } },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "backdrop",
      style: _normalizeStyle({zIndex:(__props.zIndex ?? 2) - 1})
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({ zIndex: __props.zIndex}),
      class: "loader"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(SpinnerLoader)
      ])
    ], 4)
  ], 64))
}
}

})