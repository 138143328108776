<template>
    <div class="error-view">
        <section class="error-view__header">
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 24 24" fill="none">
                <path d="M14.9 2H9.10001C8.42001 2 7.46 2.4 6.98 2.88L2.88 6.98001C2.4 7.46001 2 8.42001 2 9.10001V14.9C2 15.58 2.4 16.54 2.88 17.02L6.98 21.12C7.46 21.6 8.42001 22 9.10001 22H14.9C15.58 22 16.54 21.6 17.02 21.12L21.12 17.02C21.6 16.54 22 15.58 22 14.9V9.10001C22 8.42001 21.6 7.46001 21.12 6.98001L17.02 2.88C16.54 2.4 15.58 2 14.9 2Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 15.5L15.5 8.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.5 15.5L8.5 8.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h1 class="text-">Produto não encontrado</h1>
        </section>
        <section class="error-view__info">
            <div class="error-view__info__box">
                <h2>Não conseguimos localizar o produto. Por favor, entre em contato com o vendedor para mais informações.</h2>
            </div>
        </section>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

#app {
    height: 100vh
}

.error-view {
    background: #af1e1e;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: Inter, 'sans-serif';
    gap: 1rem;
    height: 100%;
    h2 {
        font-size: 1.1rem
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:.5rem;
        h1{
            color: #fff;
            font-size: 1.4rem;
            font-family: Inter, 'sans-serif';
        }
        padding: 3rem;
    }
    &__info {
        background: #eeeeee;
        width: 100%; 
        padding: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: 100%;
        h2 {
            color: #3a3b3b
        }
        &__box {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding: 2rem;
            border-radius: .75rem;
            position: relative;
            top: -30px;
            z-index:99;
            background: #fff;
            max-width: 1000px;
            width: 100%
        }
    }
}
</style>

<!-- [
   "facebook":[
      {
         "pixel":"222222",
         "events":[
            "checkout",
            "credit_card",
            "billet",
            "upsell"
         ]
      }
   ],
   "tabola":[
      {
         "pixel":"222222",
         "events":[
            "checkout",
            "credit_card",
            "billet",
            "upsell"
         ]
      }
   ],
   "tiktok":[
      {
         "pixel":"222222",
         "events":[
            "checkout",
            "credit_card",
            "billet",
            "upsell"
         ]
      }
   ],
   "kwai":[
      {
         "pixel":"222222",
         "events":[
            "checkout",
            "credit_card",
            "billet",
            "upsell"
         ]
      }
   ],
   "google_tag_manager":[
      {
         "pixel":"222222",
         "events":[
            "checkout",
            "credit_card",
            "billet",
            "upsell"
         ]
      }
   ],
   "google_analytics":[
      {
         "pixel":"222222",
         "events":[
            "checkout"
         ]
      }
   ],
   "google_ads":[
      {
         "pixel":"AW-33333344444",
         "events":[
            "checkout",
            "credit_card",
            "billet",
            "upsell"
         ]
      }
   ]
] -->