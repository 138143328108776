import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ThankYou from '../views/ThankYou.vue'
import ErrorView from '@/views/ErrorView.vue'
import AwaitingPixPaymentView from '@/views/AwaitingPixPaymentView.vue'
import AwaitingBilletPaymentView from '@/views/AwaitingBilletPaymentView.vue'
import UpsellView from '@/views/UpsellView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'checkout',
    component: () => import('../views/Checkout.vue'),
    meta: { title: 'HooPay - Finalização' }
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: ThankYou,
    meta: { title: 'HooPay - Obrigado' }
  },
  {
    path: '/awaiting-pix-payment',
    name: 'awaiting-pix-payment',
    component: AwaitingPixPaymentView,
    meta: { title: 'HooPay - Pix' }
  },
  {
    path: '/awaiting-billet-payment',
    name: 'awaiting-billet-payment',
    component: AwaitingBilletPaymentView,
    meta: { title: 'HooPay - Boleto' }
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView,
    meta: { title: 'HooPay - Produto não encontrado' }
  },
  {
    path: '/upsell',
    name: 'upsell',
    component: UpsellView,
    meta: { title: 'HooPay - Upsell' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title as string;

  if (title) {
    document.title = title;
  }

  next();
});

export default router
