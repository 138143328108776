<template>
    <div class="billet-view">
        <section class="awaiting-pix__payment-made pb-4 my-4">
            <h1 class="m-0">Boleto Gerado: pronto para o pagamento!</h1>
        </section>
        <section class="billet-view__info">
            <div class="billet-view__billet d-flex flex-column gap-2">
                <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <p class="m-0">Os detalhes do seu pedido serão enviados por e-mail após a compensação do pagamento do boleto.</p>
                </div>
                <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22.75C6.76 22.75 2.5 18.49 2.5 13.25C2.5 8.01 6.76 3.75 12 3.75C17.24 3.75 21.5 8.01 21.5 13.25C21.5 18.49 17.24 22.75 12 22.75ZM12 5.25C7.59 5.25 4 8.84 4 13.25C4 17.66 7.59 21.25 12 21.25C16.41 21.25 20 17.66 20 13.25C20 8.84 16.41 5.25 12 5.25Z" fill="#fff"/>
                            <path d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z" fill="#fff"/>
                            <path d="M15 2.75H9C8.59 2.75 8.25 2.41 8.25 2C8.25 1.59 8.59 1.25 9 1.25H15C15.41 1.25 15.75 1.59 15.75 2C15.75 2.41 15.41 2.75 15 2.75Z" fill="#fff"/>
                        </svg>
                    </div>
                    <p class="m-0">A confirmação do pagamento pode levar até 48 horas.</p>
                </div>   
                <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper bg-gray">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <p class="m-0">Ambiente seguro</p>
                </div>
                <div class="mt-4" v-for="(billetCharge, index) in charge?.payment.charges" :key="index">
                    <h2>Valor: <strong class="text-primary h5">{{ billetCharge.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</strong></h2>            
                    <h2>Data de vencimento: <strong>{{ new Date(billetCharge.expireAt).toLocaleDateString('pt-BR') }}</strong></h2>            
                    <h2>Código do Boleto</h2>            
                    <p><strong>{{ billetCharge.billetBarCode }}</strong></p>
                    <!-- <img width="250" :src="billetCharge.billetUrl" /> -->
                     <div class="d-flex gap-3">
                         <a target="_blank" :href="billetCharge.billetUrl">
                             Visualizar boleto
                         </a>
                         <button class="d-flex align-items-center gap-2" @click="copyBilletCode(billetCharge.billetBarCode!)">
                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                 <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                               </svg>
                             Copiar código
                         </button>
                     </div>
                </div>
            </div>
        </section>
        <iframe v-if="checkoutDetails?.thankYou?.pageBillet" :src="checkoutDetails?.thankYou?.pageBillet" style="height:40rem; width: 100%" />
    </div>
    <GetOrderComponent />
</template>

<script lang="ts" setup>
import { charge } from '@/global/states/ChargeState'
import { toast } from 'vue3-toastify'
import GetOrderComponent from './components/GetOrderComponent.vue';

const checkoutDetails = localStorage.getItem('checkoutDetails') ? JSON.parse(localStorage.getItem('checkoutDetails')!) : undefined

function copyBilletCode(pixPayload: string) {
    navigator.clipboard.writeText(pixPayload)
    .then(() => {
        toast.success('Código copiado com sucesso!')
    })
    .catch(err => {
        toast.success('Erro ao copiar o código')
    });
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

#app {
    height: 100vh
}

.billet-view {
    background: #198fe3;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: Inter, 'sans-serif';
    gap: 1rem;
    height: 100%;
    h2 {
        font-size: 1rem;
        font-weight: 400;
    }
    p, strong {
        word-break: break-all;
    }
    &__payment-made {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            color: #fff
        }
    }
    &__billet {
        img {
            margin: auto;
            width: 100%;
            max-width: 24rem;
            height: 6rem;
            object-fit: contain;
        }
        button, a {
            display: flex;
            padding: 1rem !important;
            border: none;
            background: #8170fb;
            color: #fff !important;
            border-radius: .5rem;
            text-decoration: none;
        }
    }
    &__info {
        margin: 0 auto;
        background: #f9fbfa;
        width: 100%; 
        padding: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: 100%;
        padding-top: 2rem;
        h2 {
            color: #3a3b3b
        }
    }
    &__billet {
        max-width: 525px;
    }
    .icon-wrapper {
        background: #3ccf80;
        border-radius: 50%;
        padding: .8rem .8rem;
        &.bg-gray {
            background: rgb(234, 234, 234)
        }
    }
}

@media screen and (max-width: 550px) {
    .billet-view {
        h2, p {
            font-size: .9rem !important;
        }
    }
}
</style>