function useValidation() {
    function isCNPJValid(cnpj: string): boolean {
      cnpj = cnpj.replace(/[^\d]+/g, '');
  
      if (cnpj === '' || cnpj.length !== 14) return false;
  
      // Elimina CNPJs inválidos conhecidos
      const invalidCNPJs = [
        "00000000000000", "11111111111111", "22222222222222",
        "33333333333333", "44444444444444", "55555555555555",
        "66666666666666", "77777777777777", "88888888888888",
        "99999999999999"
      ];
  
      if (invalidCNPJs.includes(cnpj)) return false;
  
      // Valida DVs
      const validateDigits = (baseCnpj: string, length: number): boolean => {
        let sum = 0;
        let pos = length - 7;
  
        for (let i = length; i >= 1; i--) {
          sum += parseInt(baseCnpj.charAt(length - i)) * pos--;
          if (pos < 2) pos = 9;
        }
  
        const result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        return result === parseInt(cnpj.charAt(length));
      };
  
      const baseCnpj = cnpj.substring(0, 12);
      if (!validateDigits(baseCnpj, 12)) return false;
      if (!validateDigits(cnpj.substring(0, 13), 13)) return false;
  
      return true;
    }
  
    function isCPFValid(strCPF: string): boolean {
      strCPF = strCPF.replace(/[^\d]+/g, '');
      var Soma;
      var Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }
  
    return {
      isCNPJValid,
      isCPFValid
    };
  }
export default useValidation