import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, vModelSelect as _vModelSelect, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "needs-validation",
  novalidate: ""
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  class: "row",
  style: {"margin":"0"}
}
const _hoisted_4 = {
  class: "col-6",
  align: "left"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "active" }
const _hoisted_8 = {
  key: 1,
  class: "col-md-12 items_list"
}
const _hoisted_9 = {
  class: "col-12",
  style: {"margin-bottom":"10px"}
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-2 cart_content center" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "col-10 ml-3 cart_content" }
const _hoisted_14 = { class: "row show-cart" }
const _hoisted_15 = {
  class: "collapse",
  id: "collapseExample"
}
const _hoisted_16 = {
  width: "100%",
  border: "0"
}
const _hoisted_17 = { style: {"text-align":"right"} }
const _hoisted_18 = { id: "amount_total" }
const _hoisted_19 = { style: {"text-align":"right"} }
const _hoisted_20 = { id: "amount_total" }
const _hoisted_21 = { style: {"text-align":"right"} }
const _hoisted_22 = { id: "amount_total" }
const _hoisted_23 = {
  width: "100%",
  border: "0"
}
const _hoisted_24 = { style: {"text-align":"right"} }
const _hoisted_25 = { id: "amount_total" }
const _hoisted_26 = { class: "d-flex form-body" }
const _hoisted_27 = { class: "container" }
const _hoisted_28 = {
  key: 0,
  class: "content"
}
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "panel with-nav-tabs panel-default" }
const _hoisted_31 = { class: "panel-heading" }
const _hoisted_32 = { class: "nav nav-tabs" }
const _hoisted_33 = { class: "panel-body" }
const _hoisted_34 = { class: "tab-content" }
const _hoisted_35 = { class: "tabs effect-3" }
const _hoisted_36 = { class: "tab-content" }
const _hoisted_37 = {
  key: 0,
  id: "tab-item-1"
}
const _hoisted_38 = { class: "col-md-6" }
const _hoisted_39 = { class: "cc[0] row" }
const _hoisted_40 = { class: "col-md-12" }
const _hoisted_41 = { for: "cc_number[0]" }
const _hoisted_42 = { class: "col-md-12" }
const _hoisted_43 = { for: "cc_holder[0]" }
const _hoisted_44 = { class: "col-md-6" }
const _hoisted_45 = { for: "cc_expiryMonth[0]" }
const _hoisted_46 = ["value", "disabled"]
const _hoisted_47 = { class: "col-md-6" }
const _hoisted_48 = { for: "cc_expiryYear[0]" }
const _hoisted_49 = ["value"]
const _hoisted_50 = { class: "col-md-12" }
const _hoisted_51 = { for: "cc_securityCode[0]" }
const _hoisted_52 = { class: "col-md-12" }
const _hoisted_53 = { for: "cc_installments[0]" }
const _hoisted_54 = ["value"]
const _hoisted_55 = {
  key: 1,
  id: "tab-item-2"
}
const _hoisted_56 = { class: "row" }
const _hoisted_57 = { class: "col-md-6" }
const _hoisted_58 = { class: "cc[0] row" }
const _hoisted_59 = { class: "col-md-12" }
const _hoisted_60 = { for: "cc_number[0]" }
const _hoisted_61 = { class: "col-md-12" }
const _hoisted_62 = { for: "cc_holder[0]" }
const _hoisted_63 = { class: "col-md-6" }
const _hoisted_64 = { for: "cc_expiryMonth[0]" }
const _hoisted_65 = ["value", "disabled"]
const _hoisted_66 = { class: "col-md-6" }
const _hoisted_67 = { for: "cc_expiryYear[1]" }
const _hoisted_68 = ["value"]
const _hoisted_69 = { class: "col-md-12" }
const _hoisted_70 = { for: "cc_securityCode[0]" }
const _hoisted_71 = { class: "col-md-12" }
const _hoisted_72 = { for: "cc_installments[0]" }
const _hoisted_73 = ["value"]
const _hoisted_74 = { class: "col-md-6" }
const _hoisted_75 = { class: "cc[1] row" }
const _hoisted_76 = { class: "col-md-12" }
const _hoisted_77 = { for: "cc_number[1]" }
const _hoisted_78 = { class: "col-md-12" }
const _hoisted_79 = { for: "cc_holder[1]" }
const _hoisted_80 = { class: "col-md-6" }
const _hoisted_81 = { for: "cc_expiryMonth[1]" }
const _hoisted_82 = ["value", "disabled"]
const _hoisted_83 = { class: "col-md-6" }
const _hoisted_84 = { for: "cc_expiryYear[1]" }
const _hoisted_85 = ["value"]
const _hoisted_86 = { class: "col-md-12" }
const _hoisted_87 = { for: "cc_securityCode[1]" }
const _hoisted_88 = { class: "col-md-12" }
const _hoisted_89 = { for: "cc_installments[1]" }
const _hoisted_90 = ["value"]
const _hoisted_91 = {
  class: "payment-method__boxes",
  style: {"display":"grid","grid-template-columns":"1fr 1fr","gap":"1rem"}
}
const _hoisted_92 = { class: "payment_box" }
const _hoisted_93 = { class: "text-primary h3" }
const _hoisted_94 = { class: "d-flex gap-2 align-items-center" }
const _hoisted_95 = {
  style: {"min-width":"20px"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_96 = {
  class: "payment-method__boxes",
  style: {"display":"grid","grid-template-columns":"1fr 1fr","gap":"1rem"}
}
const _hoisted_97 = { class: "payment_box" }
const _hoisted_98 = { class: "text-primary h3" }
const _hoisted_99 = { class: "d-flex gap-2 align-items-center" }
const _hoisted_100 = {
  style: {"min-width":"20px"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_101 = {
  key: 0,
  class: "row"
}
const _hoisted_102 = {
  class: "col-md finish",
  style: {"text-align":"center"}
}
const _hoisted_103 = ["disabled"]
const _hoisted_104 = { class: "my-4" }
const _hoisted_105 = { class: "transaction-feedback" }
const _hoisted_106 = {
  key: 0,
  class: "expiry-time"
}

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'
import { vMaska } from "maska/vue"
import axiosClient from '@/api/axios'
import useValidation from '@/utils/useValidation'
import ChargePayloadInterface from '@/interfaces/ChargePayloadInterface'
import MyModal from '@/components/modal/MyModal.vue'
import router from '@/router/index'


export default /*@__PURE__*/_defineComponent({
  __name: 'UpsellView',
  setup(__props) {

_useCssVars(_ctx => ({
  "7231f7f4": (checkoutDetails.value.checkout?.colorPrimary),
  "336e1310": (checkoutDetails.value.checkout?.colorSecondary)
}))

const errorMessages = ref<Record<string, string | null>>({})
const route = useRoute()
const validation = useValidation()
const checkoutType = ref<'stepByStep' | 'allSteps'>('allSteps')
const step = ref<number>(1)
const openApplyCoupon = ref(route.query.coupon ? true : false)
const cardsQuantity = ref<number>(1) 
const address = ref<Partial<{neighborhood: string, district: string, number: number, street: string, complement: string, state: string, city: string, ibge: string}>>({ })
const isTransactionFeedbackModalOpen = ref(false)
const payload = ref<ChargePayloadInterface>({
    name: route.query.name as string,
    email: route.query.email as string,
    document: '',
    phoneNumber: '',
    paymentMethod: 'credit_card',
    ccards: [{ expiryMonth: undefined, expiryYear: undefined }, {}],
    cep: '',
    coupon: route.query.coupon as string,
})

const checkoutDetails = ref<Record<string, any>>({});

function validateStep(step: 1 | 2 | 3): boolean{
    const stepOneIsInvalid = (!payload.value.name || !validateEmail() || !validateDocument() || !payload.value.phoneNumber || payload.value.phoneNumber?.length < 16)
    const stepTwoIsInvalid = (!payload.value.cep || payload.value.cep?.length < 9 || errorMessages.value.cep) ? true : false
    let stepThreeIsInvalid = false

    if(cardsQuantity.value == 2 && payload.value.paymentMethod == 'credit_card'){
        stepThreeIsInvalid = payload.value.ccards.some((card: any) => (card.number?.length !== 19  || card.holder?.length < 2 || !card.expiryMonth || !card.expiryYear || card.cvv?.length < 3 || !card.installments))
    } else if(cardsQuantity.value == 1 && payload.value.paymentMethod == 'credit_card'){
        stepThreeIsInvalid = payload.value.ccards[0].number?.length !== 19  || payload.value.ccards[0].holder?.length < 2 || !payload.value.ccards[0].expiryMonth || !payload.value.ccards[0].expiryYear || payload.value.ccards[0].cvv?.length < 3 || !payload.value.ccards[0].installments
    }

    if(step == 1){
        return !stepOneIsInvalid
    } else if(step == 2){
        return !stepOneIsInvalid && !stepTwoIsInvalid
    } else{
        return !stepOneIsInvalid && !stepTwoIsInvalid && !stepThreeIsInvalid
    }
}

const validateDocument = () => {
    const doc = payload.value.document;

    if (!doc) {
        errorMessages.value.document = 'Este campo é obrigatório';
        return;
    } else {
        // errorMessages.value.document = '';
    }

    if (doc.length <= 14) {
        if (!validation.isCPFValid(doc)) {
            errorMessages.value.document = 'CPF inválido';
        } else {
            errorMessages.value.document = '';
        }
    } else {
        if (!validation.isCNPJValid(doc)) {
            errorMessages.value.document = 'CNPJ inválido';
        } else {
            errorMessages.value.document = '';
        }
    }

    return !errorMessages.value.document ? true : false
};

function validateEmail(): boolean {
    let valid = true;
    const emailValue = payload.value.email?.trim() ?? '';
    const isEmpty = emailValue === '';
    const isInvalidFormat = !/\S+@\S+\.\S+/.test(emailValue);
    const hasInvalidChars = /[\(\)\<\>\,\;\:\\\/\"\[\]]/.test(emailValue);
    const hasSpaces = emailValue.includes(' ');

    if (true) {
        if (isEmpty) {
            errorMessages.value.email = "Este campo é obrigatório";
            valid = false;
        } else if (isInvalidFormat || hasInvalidChars || hasSpaces) {
            errorMessages.value.email = "Este email é inválido";
            valid = false;
        } else {
            errorMessages.value.email = "";
        }
    } 

    return valid;
}

function getCheckoutDetails(){
    axiosClient.get('/products/upsell', { params: { 'productId[]': route.query['productId[]'], 'qty[]': route.query['qty[]'] }})
    .then((res) => {
        console.log(res.data)
        checkoutDetails.value = res.data
        checkoutType.value = res.data.checkout.displayType
    })
    .catch(() => {
        router.push({ name: 'error' })
    })
}

function finishCheckout(): void{
    let payments: any

    if (payload.value.paymentMethod == 'credit_card') {
        payments = [{ type: 'creditCard', holder: payload.value.ccards[0].holder, number: payload.value.ccards[0].number, expirationDate: `${payload.value.ccards[0].expiryMonth!.toString().padStart(2, '0')}/${payload.value.ccards[0].expiryYear}`, cvv: payload.value.ccards[0].cvv, installments: payload.value.ccards[0].installments }] 
        if (cardsQuantity.value == 2) {
            payments.push({ type: 'creditCard', holder: payload.value.ccards[1].holder, number: payload.value.ccards[1].number, expirationDate: `${payload.value.ccards[1].expiryMonth!.toString().padStart(2, '0')}/${payload.value.ccards[1].expiryYear}`, cvv: payload.value.ccards[1].cvv, installments: payload.value.ccards[1].installments })
        } 
    } else if (payload.value.paymentMethod == 'pix') {
        payments = [{ type: 'pix' }]
    } else if (payload.value.paymentMethod == 'billet') {
        payments = [{ type: 'billet' }]
    }
    
    axiosClient.post('/charge', {
        url: window.location.href,
        src: route.query.src,
        customer: {
            email: payload.value.email,
            name: payload.value.name,
            phoneNumber: payload.value.phoneNumber,
            document: payload.value.document.replace(/\D/g, ''),
        },
        ...(payload.value.cep && payload.value.cep.trim() !== '' && { address: { 
            neighborhood: address.value.neighborhood, 
            district: address.value.district, 
            streetNumber: address.value.number, 
            street: address.value.street, 
            complement: address.value.complement, 
            state: address.value.state, 
            city: address.value.city, 
            zipcode: payload.value.cep, 
            // codeIbge: address.value.ibge, 
        }}),
        products: checkoutDetails.value.products.map((product: any) => ({ variantUUID: product.variantUUID, quantity: product.quantity })),
        payments,
    })
    .then((res) => {
        if(res.data.payment.hasErrors){
            isTransactionFeedbackModalOpen.value = true
        } else {
            if (payload.value.paymentMethod == 'credit_card' && checkoutDetails.value.thankYou.pageCreditCard) {
                window.location.href = checkoutDetails.value.thankYou.pageCreditCard
            } else if (payload.value.paymentMethod == 'pix' && checkoutDetails.value.thankYou.pagePix) {
                window.location.href = checkoutDetails.value.thankYou.pagePix
            } else if (payload.value.paymentMethod == 'billet' && checkoutDetails.value.thankYou.pageBillet) {
                window.location.href = checkoutDetails.value.thankYou.pageBillet
            } else {
                router.push({ name: 'thank-you' })
            }
        }
    })
    .catch(() => {
        isTransactionFeedbackModalOpen.value = true
    })
}

onMounted(() => {
    getCheckoutDetails()
    // window.JS_store = "L1VOvGVAeJZD62e";
    // window.JS_checkout_id = "PyE2ZyKwa4JgqRb";
    // window.JS_mobile = false;
    // window.JS_payment_done_boleto_url = null;
    // window.JS_payment_done_card_url = null;
    // window.JS_payment_done_pix_url = null;
    // window.JS_payment_done_analysing_url = null;
    // window.JS_total_value = "147.00";
    // window.JS_discount_coupon = "";
    // window.JS_payment_boleto = 0;
    // window.JS_payment_pix = 1;
    // window.JS_payment_credit_card = 1;
    // window.JS_automatic_discount_bank_slip = 0;
    // window.JS_automatic_discount_credit_card = 0;
    // window.JS_automatic_discount_pix = 0;
    // window.JS_content_pixels = {
    //     "value": "147.00",
    //     "currency": "BRL",
    //     "contents": "[{\"id\":\"NDr8gmlM0LEGBmj\",\"quantity\":1}]",
    //     "content_ids": "[{\"id\":\"NDr8gmlM0LEGBmj\"}]",
    //     "ecomm_prodid": [{"id": "NDr8gmlM0LEGBmj"}],
    //     "num_items": 1,
    //     "content_type": "product",
    //     "checkoutId": "PyE2ZyKwa4JgqRb"
    // };
    // window.JS_checkout_type = 2;
    // window.JS_checkout_template = 2;
    // window.JS_pre_selected_installment = 12;
    // window.JS_pixels_data = [];
    // window.JS_email_required = 1;
    // window.JS_log = "";
    // window.JS_has_physical_product = false;
    // window.JS_document_type = 3;
    // window.JS_original_subtotal = 14700;
    // window.JS_can_change_product_amount = true;
    // window.JS_require_dependencies = true;
    // window.JS_default_credit_card_gateway_id = 32;
    // window.JS_countdown_time = 10;
    // window.JS_countdown_timer_finished_message = "Seu tempo acabou, adquira seu acesso agora mesmo.";
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", _hoisted_1, [
      _createElementVNode("header", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: checkoutDetails.value.checkout?.checkoutLogo ?? 'https://cdn.hoopay.com.br/images/logo/logo-brand-dark.png',
                style: {"height":"35px"}
              }, null, 8, _hoisted_5)
            ]),
            _cache[38] || (_cache[38] = _createElementVNode("div", {
              class: "col-6",
              align: "right",
              style: {"color":"#737380"}
            }, [
              _createTextVNode(" Compra Segura "),
              _createElementVNode("img", {
                src: "https://cdn.hoopay.com.br/images/checkout/secure_checkout.png",
                height: "15"
              })
            ], -1)),
            (checkoutType.value == 'stepByStep')
              ? (_openBlock(), _createElementBlock("nav", _hoisted_6, [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", _hoisted_7, [
                      _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                      }, "1")
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass({active: step.value >= 2})
                    }, null, 2),
                    _createElementVNode("li", {
                      class: _normalizeClass({active: step.value >= 2})
                    }, [
                      _createElementVNode("button", {
                        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                      }, "2")
                    ], 2),
                    _createElementVNode("span", {
                      class: _normalizeClass({active: step.value == 3})
                    }, null, 2),
                    _createElementVNode("li", {
                      class: _normalizeClass({active: step.value == 3})
                    }, [
                      _createElementVNode("button", {
                        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                      }, "3")
                    ], 2)
                  ])
                ]))
              : _createCommentVNode("", true),
            ((checkoutType.value == 'stepByStep' && step.value == 3) || checkoutType.value == 'allSteps')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[36] || (_cache[36] = _createElementVNode("h3", { class: "section_title" }, " Carrinho ", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkoutDetails.value.products, (product) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("img", {
                            src: product.image ? product.image: 'https://cdn.hoopay.com.br/images/checkout/no-image.png',
                            style: {"max-height":"60px"}
                          }, null, 8, _hoisted_12)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("p", null, _toDisplayString(product.title), 1),
                            _createElementVNode("p", null, _toDisplayString(product.promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                          ])
                        ])
                      ]))
                    }), 256))
                  ]),
                  _cache[37] || (_cache[37] = _createElementVNode("p", { class: "d-flex justify-content-end" }, [
                    _createElementVNode("a", {
                      class: "arrow-icon open",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": "#collapseExample",
                      "aria-expanded": "false",
                      "aria-controls": "collapseExample"
                    }, [
                      _createElementVNode("span", { class: "left-bar" }),
                      _createElementVNode("span", { class: "right-bar" })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("table", _hoisted_16, [
                      _createElementVNode("tfoot", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_17, [
                            _cache[28] || (_cache[28] = _createTextVNode("SubTotal")),
                            _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", _hoisted_18, _toDisplayString(checkoutDetails.value.amount?.subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                          ])
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_19, [
                            _cache[30] || (_cache[30] = _createTextVNode("Desconto")),
                            _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", _hoisted_20, _toDisplayString(checkoutDetails.value.amount?.discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                          ])
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_21, [
                            _cache[32] || (_cache[32] = _createTextVNode("Frete")),
                            _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", _hoisted_22, _toDisplayString(checkoutDetails.value.amount?.shipping.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("table", _hoisted_23, [
                    _createElementVNode("tfoot", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", _hoisted_24, [
                          _cache[34] || (_cache[34] = _createTextVNode("Total")),
                          _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("span", _hoisted_25, _toDisplayString(checkoutDetails.value.amount?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          ((checkoutType.value == 'stepByStep' && step.value == 3) || checkoutType.value == 'allSteps')
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[73] || (_cache[73] = _createElementVNode("h3", { class: "section_title" }, [
                    _createElementVNode("div", { class: "dot" }, "3"),
                    _createTextVNode(" Forma de Pagamento ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("ul", _hoisted_32, [
                        _createElementVNode("li", {
                          class: _normalizeClass(["box_method_payment button_method_payment button_credit_card col-md", payload.value.paymentMethod == 'credit_card' ? 'active' : '']),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (payload.value.paymentMethod = 'credit_card'))
                        }, _cache[39] || (_cache[39] = [
                          _createElementVNode("a", null, [
                            _createElementVNode("img", {
                              src: "https://cdn.hoopay.com.br/images/checkout/credit_card.png",
                              height: "25"
                            }),
                            _createTextVNode(" Cartão de Crédito ")
                          ], -1)
                        ]), 2),
                        _createElementVNode("li", {
                          class: _normalizeClass(["box_method_payment button_method_payment button_pix col-md", payload.value.paymentMethod == 'pix' ? 'active' : '']),
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (payload.value.paymentMethod = 'pix'))
                        }, _cache[40] || (_cache[40] = [
                          _createElementVNode("a", null, [
                            _createElementVNode("img", {
                              src: "https://cdn.hoopay.com.br/images/checkout/pix.png",
                              height: "25"
                            }),
                            _createTextVNode(" Pix")
                          ], -1)
                        ]), 2),
                        _createElementVNode("li", {
                          class: _normalizeClass(["box_method_payment button_method_payment button_billet col-md", payload.value.paymentMethod == 'billet' ? 'active' : '']),
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (payload.value.paymentMethod = 'billet'))
                        }, _cache[41] || (_cache[41] = [
                          _createElementVNode("a", null, [
                            _createElementVNode("img", {
                              src: "https://cdn.hoopay.com.br/images/checkout/billet.png",
                              height: "25"
                            }),
                            _createTextVNode(" Boleto ")
                          ], -1)
                        ]), 2)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["tab-pane fade methods_payment box_credit_card", payload.value.paymentMethod == 'credit_card' ? 'in active' : 'fade']),
                          id: "tab1default"
                        }, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("input", {
                              type: "radio",
                              id: "tab-1",
                              name: "tab-effect-3",
                              checked: "",
                              onChange: _cache[6] || (_cache[6] = ($event: any) => (($event.target as HTMLInputElement).checked ? cardsQuantity.value = 1 : cardsQuantity.value = 2))
                            }, null, 32),
                            _cache[61] || (_cache[61] = _createElementVNode("span", null, "Pagar com UM cartão", -1)),
                            _createElementVNode("input", {
                              type: "radio",
                              id: "tab-2",
                              name: "tab-effect-3",
                              onChange: _cache[7] || (_cache[7] = ($event: any) => (($event.target as HTMLInputElement).checked ? cardsQuantity.value = 2 : cardsQuantity.value = 1))
                            }, null, 32),
                            _cache[62] || (_cache[62] = _createElementVNode("span", null, "Pagar com DOIS cartões", -1)),
                            _cache[63] || (_cache[63] = _createElementVNode("div", { class: "line ease" }, null, -1)),
                            _cache[64] || (_cache[64] = _createElementVNode("br", null, null, -1)),
                            _cache[65] || (_cache[65] = _createElementVNode("br", null, null, -1)),
                            _cache[66] || (_cache[66] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("div", _hoisted_36, [
                              (cardsQuantity.value == 1)
                                ? (_openBlock(), _createElementBlock("section", _hoisted_37, [
                                    _createElementVNode("div", _hoisted_38, [
                                      _createElementVNode("div", _hoisted_39, [
                                        _createElementVNode("div", _hoisted_40, [
                                          _createElementVNode("label", _hoisted_41, [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "tel",
                                              name: "cc_number[0]",
                                              id: "cc_number[0]",
                                              class: "form-control",
                                              placeholder: " ",
                                              required: "",
                                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((payload.value.ccards[0].number) = $event))
                                            }, null, 512), [
                                              [_unref(vMaska), '#### #### #### ####'],
                                              [_vModelText, payload.value.ccards[0].number]
                                            ]),
                                            _cache[42] || (_cache[42] = _createElementVNode("span", null, "Número do Cartão*", -1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_42, [
                                          _createElementVNode("label", _hoisted_43, [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "text",
                                              name: "cc_holder[0]",
                                              id: "cc_holder[0]",
                                              class: "form-control",
                                              placeholder: " ",
                                              required: "",
                                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((payload.value.ccards[0].holder) = $event))
                                            }, null, 512), [
                                              [_vModelText, payload.value.ccards[0].holder]
                                            ]),
                                            _cache[43] || (_cache[43] = _createElementVNode("span", null, "Nome impresso no cartão*", -1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_44, [
                                          _createElementVNode("label", _hoisted_45, [
                                            _withDirectives(_createElementVNode("select", {
                                              type: "text",
                                              name: "cc_expiryMonth[0]",
                                              id: "cc_expiryMonth[0]",
                                              class: "form-control",
                                              placeholder: " ",
                                              required: "",
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((payload.value.ccards[0].expiryMonth) = $event))
                                            }, [
                                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4,5,6,7,8,9,10,11,12], (i) => {
                                                return _createElementVNode("option", {
                                                  value: i,
                                                  disabled: payload.value.ccards[0].expiryYear == new Date().getFullYear() && i < (new Date().getMonth() + 1)
                                                }, _toDisplayString(i), 9, _hoisted_46)
                                              }), 64))
                                            ], 512), [
                                              [_vModelSelect, payload.value.ccards[0].expiryMonth]
                                            ]),
                                            _cache[44] || (_cache[44] = _createElementVNode("span", null, "Mês de Vencimento*", -1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_47, [
                                          _createElementVNode("label", _hoisted_48, [
                                            _withDirectives(_createElementVNode("select", {
                                              type: "text",
                                              name: "cc_expiryYear[0]",
                                              id: "cc_expiryYear[0]",
                                              class: "form-control",
                                              placeholder: " ",
                                              required: "",
                                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((payload.value.ccards[0].expiryYear) = $event))
                                            }, [
                                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0,1,2,3,4,5,6,7,8,9,10,], (i) => {
                                                return _createElementVNode("option", {
                                                  value: new Date().getFullYear() + i
                                                }, _toDisplayString(new Date().getFullYear() + i), 9, _hoisted_49)
                                              }), 64))
                                            ], 512), [
                                              [_vModelSelect, payload.value.ccards[0].expiryYear]
                                            ]),
                                            _cache[45] || (_cache[45] = _createElementVNode("span", null, "Ano de Vencimento*", -1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_50, [
                                          _createElementVNode("label", _hoisted_51, [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "tel",
                                              name: "cc_securityCode[0]",
                                              id: "cc_securityCode[0]",
                                              pattern: "/^-?\\d+\\.?\\d*$/",
                                              onKeyPress: "if(this.value.length==4) return false;",
                                              class: "form-control",
                                              placeholder: " ",
                                              required: "",
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((payload.value.ccards[0].cvv) = $event))
                                            }, null, 512), [
                                              [_unref(vMaska), '####'],
                                              [_vModelText, payload.value.ccards[0].cvv]
                                            ]),
                                            _cache[46] || (_cache[46] = _createElementVNode("span", null, "Cód. de Segurança (CVV)*", -1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_52, [
                                          _createElementVNode("label", _hoisted_53, [
                                            _withDirectives(_createElementVNode("select", {
                                              type: "text",
                                              name: "cc_installments[0]",
                                              id: "cc_installments[0]",
                                              class: "form-control",
                                              placeholder: " ",
                                              required: "",
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((payload.value.ccards[0].installments) = $event))
                                            }, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkoutDetails.value.installments, (installment, i) => {
                                                return (_openBlock(), _createElementBlock("option", {
                                                  value: installment.installment
                                                }, _toDisplayString(installment.installment) + "x de " + _toDisplayString(installment.installmentAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })) + " (" + _toDisplayString(installment.textInstallmentsInterest) + ")", 9, _hoisted_54))
                                              }), 256))
                                            ], 512), [
                                              [_vModelSelect, payload.value.ccards[0].installments]
                                            ]),
                                            _cache[47] || (_cache[47] = _createElementVNode("span", null, "Parcelas*", -1))
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _cache[48] || (_cache[48] = _createElementVNode("div", { class: "col-md-12" }, [
                                      _createElementVNode("div", { class: "cc_display[0]" })
                                    ], -1))
                                  ]))
                                : _createCommentVNode("", true),
                              (cardsQuantity.value == 2)
                                ? (_openBlock(), _createElementBlock("section", _hoisted_55, [
                                    _createElementVNode("div", _hoisted_56, [
                                      _createElementVNode("div", _hoisted_57, [
                                        _createElementVNode("div", _hoisted_58, [
                                          _createElementVNode("div", _hoisted_59, [
                                            _createElementVNode("label", _hoisted_60, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "tel",
                                                name: "cc_number[0]",
                                                id: "cc_number[0]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((payload.value.ccards[0].number) = $event))
                                              }, null, 512), [
                                                [_unref(vMaska), '#### #### #### ####'],
                                                [_vModelText, payload.value.ccards[0].number]
                                              ]),
                                              _cache[49] || (_cache[49] = _createElementVNode("span", null, "Número do Cartão*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_61, [
                                            _createElementVNode("label", _hoisted_62, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "text",
                                                name: "cc_holder[0]",
                                                id: "cc_holder[0]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((payload.value.ccards[0].holder) = $event))
                                              }, null, 512), [
                                                [_vModelText, payload.value.ccards[0].holder]
                                              ]),
                                              _cache[50] || (_cache[50] = _createElementVNode("span", null, "Nome impresso no cartão*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_63, [
                                            _createElementVNode("label", _hoisted_64, [
                                              _withDirectives(_createElementVNode("select", {
                                                type: "text",
                                                name: "cc_expiryMonth[0]",
                                                id: "cc_expiryMonth[0]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((payload.value.ccards[0].expiryMonth) = $event))
                                              }, [
                                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4,5,6,7,8,9,10,11,12], (i) => {
                                                  return _createElementVNode("option", {
                                                    value: i,
                                                    disabled: payload.value.ccards[1].expiryYear == new Date().getFullYear() && i < (new Date().getMonth() + 1)
                                                  }, _toDisplayString(i), 9, _hoisted_65)
                                                }), 64))
                                              ], 512), [
                                                [_vModelSelect, payload.value.ccards[0].expiryMonth]
                                              ]),
                                              _cache[51] || (_cache[51] = _createElementVNode("span", null, "Mês de Vencimento*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_66, [
                                            _createElementVNode("label", _hoisted_67, [
                                              _withDirectives(_createElementVNode("select", {
                                                type: "text",
                                                id: "cc_expiryYear[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((payload.value.ccards[0].expiryYear) = $event))
                                              }, [
                                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0,1,2,3,4,5,6,7,8,9,10,], (i) => {
                                                  return _createElementVNode("option", {
                                                    value: new Date().getFullYear() + i
                                                  }, _toDisplayString(new Date().getFullYear() + i), 9, _hoisted_68)
                                                }), 64))
                                              ], 512), [
                                                [_vModelSelect, payload.value.ccards[0].expiryYear]
                                              ]),
                                              _cache[52] || (_cache[52] = _createElementVNode("span", null, "Ano de Vencimento*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_69, [
                                            _createElementVNode("label", _hoisted_70, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "tel",
                                                name: "cc_securityCode[0]",
                                                id: "cc_securityCode[0]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((payload.value.ccards[0].cvv) = $event))
                                              }, null, 512), [
                                                [_unref(vMaska), '####'],
                                                [_vModelText, payload.value.ccards[0].cvv]
                                              ]),
                                              _cache[53] || (_cache[53] = _createElementVNode("span", null, "Cód. de Segurança*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_71, [
                                            _createElementVNode("label", _hoisted_72, [
                                              _withDirectives(_createElementVNode("select", {
                                                type: "text",
                                                name: "cc_installments[0]",
                                                id: "cc_installments[0]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((payload.value.ccards[0].installments) = $event))
                                              }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkoutDetails.value.installments, (installment, i) => {
                                                  return (_openBlock(), _createElementBlock("option", {
                                                    value: installment.installment
                                                  }, _toDisplayString(installment.installment) + "x de " + _toDisplayString(installment.installmentAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })) + " (" + _toDisplayString(installment.textInstallmentsInterest) + ")", 9, _hoisted_73))
                                                }), 256))
                                              ], 512), [
                                                [_vModelSelect, payload.value.ccards[0].installments]
                                              ]),
                                              _cache[54] || (_cache[54] = _createElementVNode("span", null, "Parcelas*", -1))
                                            ])
                                          ])
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_74, [
                                        _createElementVNode("div", _hoisted_75, [
                                          _createElementVNode("div", _hoisted_76, [
                                            _createElementVNode("label", _hoisted_77, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "tel",
                                                name: "cc_number[1]",
                                                id: "cc_number[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((payload.value.ccards[1].number) = $event))
                                              }, null, 512), [
                                                [_unref(vMaska), '#### #### #### ####'],
                                                [_vModelText, payload.value.ccards[1].number]
                                              ]),
                                              _cache[55] || (_cache[55] = _createElementVNode("span", null, "Número do Cartão*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_78, [
                                            _createElementVNode("label", _hoisted_79, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "text",
                                                name: "cc_holder[1]",
                                                id: "cc_holder[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((payload.value.ccards[1].holder) = $event))
                                              }, null, 512), [
                                                [_vModelText, payload.value.ccards[1].holder]
                                              ]),
                                              _cache[56] || (_cache[56] = _createElementVNode("span", null, "Nome impresso no cartão*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_80, [
                                            _createElementVNode("label", _hoisted_81, [
                                              _withDirectives(_createElementVNode("select", {
                                                type: "text",
                                                name: "cc_expiryMonth[1]",
                                                id: "cc_expiryMonth[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((payload.value.ccards[1].expiryMonth) = $event))
                                              }, [
                                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4,5,6,7,8,9,10,11,12], (i) => {
                                                  return _createElementVNode("option", {
                                                    value: i,
                                                    disabled: payload.value.ccards[1].expiryYear == new Date().getFullYear() && i < (new Date().getMonth() + 1)
                                                  }, _toDisplayString(i), 9, _hoisted_82)
                                                }), 64))
                                              ], 512), [
                                                [_vModelSelect, payload.value.ccards[1].expiryMonth]
                                              ]),
                                              _cache[57] || (_cache[57] = _createElementVNode("span", null, "Mês de Vencimento*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_83, [
                                            _createElementVNode("label", _hoisted_84, [
                                              _withDirectives(_createElementVNode("select", {
                                                type: "text",
                                                name: "cc_expiryYear[1]",
                                                id: "cc_expiryYear[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((payload.value.ccards[1].expiryYear) = $event))
                                              }, [
                                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0,1,2,3,4,5,6,7,8,9,10,], (i) => {
                                                  return _createElementVNode("option", {
                                                    value: new Date().getFullYear() + i
                                                  }, _toDisplayString(new Date().getFullYear() + i), 9, _hoisted_85)
                                                }), 64))
                                              ], 512), [
                                                [_vModelSelect, payload.value.ccards[1].expiryYear]
                                              ]),
                                              _cache[58] || (_cache[58] = _createElementVNode("span", null, "Ano de Vencimento*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_86, [
                                            _createElementVNode("label", _hoisted_87, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "tel",
                                                name: "cc_securityCode[1]",
                                                id: "cc_securityCode[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((payload.value.ccards[1].cvv) = $event))
                                              }, null, 512), [
                                                [_unref(vMaska), '####'],
                                                [_vModelText, payload.value.ccards[1].cvv]
                                              ]),
                                              _cache[59] || (_cache[59] = _createElementVNode("span", null, "Cód. de Segurança*", -1))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_88, [
                                            _createElementVNode("label", _hoisted_89, [
                                              _withDirectives(_createElementVNode("select", {
                                                type: "text",
                                                name: "cc_installments[1]",
                                                id: "cc_installments[1]",
                                                class: "form-control",
                                                placeholder: " ",
                                                required: "",
                                                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((payload.value.ccards[1].installments) = $event))
                                              }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkoutDetails.value.installments, (installment, i) => {
                                                  return (_openBlock(), _createElementBlock("option", {
                                                    value: installment.installment
                                                  }, _toDisplayString(installment.installment) + "x de " + _toDisplayString(installment.installmentAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })) + " (" + _toDisplayString(installment.textInstallmentsInterest) + ")", 9, _hoisted_90))
                                                }), 256))
                                              ], 512), [
                                                [_vModelSelect, payload.value.ccards[1].installments]
                                              ]),
                                              _cache[60] || (_cache[60] = _createElementVNode("span", null, "Parcelas*", -1))
                                            ])
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass(["tab-pane methods_payment box_billet row", payload.value.paymentMethod == 'billet' ? 'in active' : 'fade'])
                        }, [
                          _createElementVNode("div", _hoisted_91, [
                            _createElementVNode("div", _hoisted_92, [
                              _createElementVNode("h4", _hoisted_93, _toDisplayString(checkoutDetails.value.amount?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1),
                              _createElementVNode("div", _hoisted_94, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_95, _cache[67] || (_cache[67] = [
                                  _createElementVNode("path", {
                                    d: "M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }, null, -1),
                                  _createElementVNode("path", {
                                    d: "M7.75 11.9999L10.58 14.8299L16.25 9.16992",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }, null, -1)
                                ]))),
                                _cache[68] || (_cache[68] = _createElementVNode("p", { class: "m-0" }, "Não é possível parcelar um boleto.", -1))
                              ])
                            ]),
                            _cache[69] || (_cache[69] = _createStaticVNode("<div class=\"payment_box\" data-v-ac3f6f16><div data-v-ac3f6f16><img src=\"https://cdn.hoopay.com.br/images/checkout/clock.png\" height=\"15\" data-v-ac3f6f16></div><div class=\"payment_box_title\" data-v-ac3f6f16>Pague até a data de vencimento</div> Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do vencimento. </div><div class=\"payment_box\" data-v-ac3f6f16><div data-v-ac3f6f16><img src=\"https://cdn.hoopay.com.br/images/checkout/clock.png\" height=\"15\" data-v-ac3f6f16></div><div class=\"payment_box_title\" data-v-ac3f6f16>Aguarde a aprovação do pagamento</div> Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os produtos liberados. </div>", 2))
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass(["tab-pane fade methods_payment box_pix row", payload.value.paymentMethod == 'pix' ? 'in active' : 'fade']),
                          id: "tab3default"
                        }, [
                          _createElementVNode("div", _hoisted_96, [
                            _createElementVNode("div", _hoisted_97, [
                              _createElementVNode("h4", _hoisted_98, _toDisplayString(checkoutDetails.value.amount?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1),
                              _createElementVNode("div", _hoisted_99, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_100, _cache[70] || (_cache[70] = [
                                  _createElementVNode("path", {
                                    d: "M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }, null, -1),
                                  _createElementVNode("path", {
                                    d: "M7.75 11.9999L10.58 14.8299L16.25 9.16992",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }, null, -1)
                                ]))),
                                _cache[71] || (_cache[71] = _createElementVNode("p", { class: "m-0" }, "Basta usar o aplicativo do seu banco para realizar um pagamento via PIX. É fácil e rápido!", -1))
                              ])
                            ]),
                            _cache[72] || (_cache[72] = _createElementVNode("div", null, [
                              _createElementVNode("div", { class: "payment_box" }, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("img", {
                                    src: "https://cdn.hoopay.com.br/images/checkout/clock.png",
                                    height: "15"
                                  })
                                ]),
                                _createElementVNode("div", { class: "payment_box_title" }, "Pagamento Instantâneo"),
                                _createTextVNode(" Pagamentos com Pix são processados instantaneamente, porem em alguns bancos pode demorar alguns minutos! ")
                              ])
                            ], -1))
                          ])
                        ], 2)
                      ])
                    ])
                  ])
                ]),
                ((checkoutType.value == 'stepByStep' && step.value == 3) || checkoutType.value == 'allSteps')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                      _createElementVNode("div", _hoisted_102, [
                        (checkoutType.value == 'stepByStep')
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              onClick: _cache[26] || (_cache[26] = ($event: any) => (step.value = 2)),
                              class: "go-back"
                            }, "Voltar"))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", {
                          type: "submit",
                          class: "button_finish mx-auto",
                          disabled: !validateStep(3),
                          onClick: _withModifiers(finishCheckout, ["prevent"])
                        }, [
                          _cache[74] || (_cache[74] = _createElementVNode("img", {
                            src: "https://cdn.hoopay.com.br/images/checkout/secure_checkout_light.png",
                            height: "15"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(payload.value.paymentMethod == 'pix' ? 'Gerar pix' : 'Finalizar'), 1)
                        ], 8, _hoisted_103)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("footer", _hoisted_104, [
                  _createElementVNode("p", null, "Hoopay está processando este pagamento para o vendedor " + _toDisplayString(), 1),
                  _cache[75] || (_cache[75] = _createElementVNode("p", null, "Este site é protegido pelo reCAPTCHA do Google", -1)),
                  _cache[76] || (_cache[76] = _createElementVNode("p", null, "Política de privacidade e Termos de serviço", -1)),
                  _cache[77] || (_cache[77] = _createElementVNode("p", null, "* Parcelamento com acréscimo", -1)),
                  _cache[78] || (_cache[78] = _createElementVNode("p", null, "Ao continuar, você concorda com os Termos de Com pra", -1))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(MyModal, {
      isOpen: isTransactionFeedbackModalOpen.value,
      width: '420px',
      height: '230px',
      toggleModal: (v) => isTransactionFeedbackModalOpen.value = v
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_105, [
          _cache[79] || (_cache[79] = _createElementVNode("h6", null, "Transação não concluída", -1)),
          _cache[80] || (_cache[80] = _createElementVNode("p", null, "No momento não é possível concluir essa compra", -1)),
          _createElementVNode("button", {
            onClick: _cache[27] || (_cache[27] = ($event: any) => (isTransactionFeedbackModalOpen.value = false))
          }, "Ok, entendi")
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_106, _cache[81] || (_cache[81] = [
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, [
              _createElementVNode("span", null, "07:23"),
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "29",
                height: "29",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z",
                  stroke: "#fff",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }),
                _createElementVNode("path", {
                  d: "M12 8V13",
                  stroke: "#fff",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }),
                _createElementVNode("path", {
                  d: "M9 2H15",
                  stroke: "#fff",
                  "stroke-width": "1.5",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ])
            ]),
            _createTextVNode(" Não perca essa oportunidade! Preço promocional acabará em breve.. ")
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}
}

})